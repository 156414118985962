<template>
    <modal
        id="orderSuccess"
        ref="orderSuccess"
        :backdrop-close="false"
        :title="title"
        type="user"
    >
        <div class="order-confirmation d-flex">
            <img
                :src="imageUrl"
                alt="order confirmed"
            >
            <h4>{{ heading }}</h4>
            <p
                v-if="attempt < 2 && message"
                class="sbold"
                :class="statusClass"
            >
                {{ message }}
            </p>
            <p class="sbold">
                {{ info }}
            </p>
        </div>
    </modal>
</template>

<script>
    import Modal from './Modal';
    export default {
        name: 'OrderConfirmationModal',
        props: {
            paymentStatus: {
                type: String,
                default: '',
            },
            message: {
                type: String,
                default: '',
            },
            attempt: {
                type: Number,
                default: 0,
            },
        },
        computed: {
            isDeclined(){
                return this.paymentStatus == 'DECLINED';
            },
            heading() {
                if(this.isDeclined){
                    if(this.attempt < 2){
                        return this.$t('modal.orderConfirmation.paymentHeading') + ':';
                    }else{
                        return this.$t('modal.orderConfirmation.paymentHeading');
                    }
                }
                return this.$t('modal.orderConfirmation.heading');
            },
            info() {
                if(this.isDeclined){
                    if(this.attempt < 2){
                        return this.$t('modal.orderConfirmation.paymentInfoText');
                    }else{
                        return this.$t('modal.orderConfirmation.paymentAttemptExceededInfoText');
                    }
                }
                return this.$t('modal.orderConfirmation.infoText');
            },
            imageUrl() {
                return this.isDeclined
                    ? '/images/payment-declined.webp'
                    : '/images/order-confirmed-blue.webp';

            },
            title() {
                return this.isDeclined
                    ? this.$t('modal.orderConfirmation.paymentTitle')
                    : this.$t('modal.orderConfirmation.title');
            },
            statusClass() {
                return this.paymentStatus.toLowerCase();
            },
        },
        components: {
            Modal,
        },
    };
</script>
<style scoped lang="scss">
.order-confirmation {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 24px 0;
    height: 70px;
  }
  h4 {
    color: $text;
  }
  p {
    color: $text;
    text-align: center;
    &:first-of-type {
      &.declined {
        color: $red;
      }
    }
    &:last-of-type {
      margin-bottom: 8px;
    }
  }
}
</style>
